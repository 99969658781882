<template>
  <v-dialog :value="value" width="350" @click:outside="$emit('cancel')">
    <v-card>
      <v-card-title>
        Confirm Move
      </v-card-title>
      <v-card-text>
        Moving this feature will result in deletion of the original. Continue?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text :class="body" @click="$emit('cancel')">
          Cancel
        </v-btn>
        <v-btn color="success" :class="body" @click="confirm">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { displayHelpers } from '@/mixins/display-helpers'
export default {
  name: 'FeatureMoveDialog',
  props: {
    value: Boolean
  },
  mixins: [displayHelpers],
  methods: {
    confirm() {
      this.$emit('confirmMove')
    }
  }
}
</script>