<template>
<v-dialog :value="value" width="600" @click:outside="$emit('update:value', false)">
  <v-card color="fill">
    <v-card-title :class="title">Move/Copy Ad Feature</v-card-title>
    <v-container class="ma-0 pa-0 px-6 pt-6" fluid>
      <v-form ref="form" v-model="valid">
        <v-row justify="center" align="center" dense>
          <v-col>
            <v-autocomplete
              v-model="destinationAdId"
              label="Destination Ad"
              :items="ads"
              :class="body"
              :disabled="loading"
              :loading="loadingAds"
              auto-select-first 
              item-text="ad_name"
              item-value="id"
              background-color="input"
              autocomplete="off"
              outlined dense
              :menu-props="{ maxHeight: 220, rounded: '' }"
              clearable>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.ad_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.ad_group_name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <!-- <v-spacer/>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ formatDateDisplay(item.start_date) + " - " + formatDateDisplay(item.end_date) }}
                  </v-list-item-subtitle>
                </v-list-item-content> -->
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" dense>
          <v-col>
            <v-autocomplete 
              v-model="destinationPage"
              label="Destination Page"
              :items="pages"
              :class="body"
              :disabled="loading"
              :loading="pages.length === 0"
              item-text="name"
              item-value="constant"
              background-color="input"
              autocomplete="off"
              outlined dense
              :menu-props="{ maxHeight: 220, rounded: '' }"
              clearable />
          </v-col>
          <v-col>
            <v-autocomplete 
              v-model="destinationFeaturePositionId"
              label="Destination Position"
              :items="featurePositions"
              :class="body"
              :disabled="loading"
              :loading="loadingFeaturePositions"
              item-text="name"
              item-value="id"
              background-color="input"
              autocomplete="off"
              outlined dense
              :menu-props="{ maxHeight: 220, rounded: '' }"
              clearable />
          </v-col>
        </v-row>
        <v-divider />
      </v-form>
    </v-container>
    <v-toolbar flat color="fill">
      <v-spacer />
      <v-btn text :class="body" @click="$emit('update:value', false)">
        Cancel
      </v-btn>
      <v-btn :disabled="moveCopyDisabled" color="warning" :class="body" class="white--text ml-2" @click="openConfirmDialog">
        Move
      </v-btn>
      <v-btn :disabled="moveCopyDisabled" color="save" :class="body" class="white--text ml-2" @click="copy('copy')">
        Copy
      </v-btn>
    </v-toolbar>
  </v-card>
  <FeatureMoveDialog v-if="confirmDialog" v-model="confirmDialog" @cancel="closeConfirmDialog" @confirmMove="confirmMove"/>
</v-dialog>
</template>
<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { notification } from '@/mixins/notification'
import { format } from '@/mixins/format'
import { userAccess } from '@/mixins/user-access'
import FeatureMoveDialog from '@/components/features/feature-move-dialog'
import Ads from '@/axios/ad-endpoints'
import Features from '@/axios/feature-endpoints'
export default {
  name: 'FeatureMoveCopy',
  mixins: [displayHelpers, format, notification, userAccess],
  components: { FeatureMoveDialog },
  props: ['value', 'selectedAd', 'selectedFeature'],
  data() {
    return {
      confirmDialog: false,
      destinationAdId: null,
      destinationFeaturePositionId: null,
      destinationPage: null,
      ads: [],
      loadingAds: [],
      featurePositions: [],
      loadingFeaturePositions: false,
      copyInProgress: false,
      valid: true
    }
  },
  computed: {
    pages () {
      return this.$store.getters.adPages
    },
    moveCopyDisabled () {
      return this.loading || !(this.destinationAdId && this.destinationPage && this.destinationFeaturePositionId)
    },
    loading () {
      return this.loadingAds || this.loadingFeaturePositions || this.copyInProgress
    }
  },
  async created() {
    await this.getAds()
  },
  watch: {
    pages: {
      immediate: true,
      handler (newValue) {
        if (newValue.length > 0) {
          this.destinationPage = newValue[0].constant
        }
      }
    },
    destinationAdId: {
      async handler () {
        if (this.ads.length > 0) {
          await this.getFeaturePositions()
        }
      }
    },
    destinationPage: {
      async handler () {
        if (this.ads.length > 0) {
          await this.getFeaturePositions()
        }
      }
    },
  },
  methods: {
    async getAds () {
      try {
        this.loadingAds = true
        const nonPromoOnly = this.selectedFeature.promo_item_group_ids.length === 0
        const { data } = await Ads.getValidCopyDestinations(this.selectedAd.ad_party_id, this.selectedFeature.id, nonPromoOnly)
        this.ads = data
        if (this.ads.length > 0) {
          this.destinationAdId = this.ads[0].id
        }
      } catch (e) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to retrieve ad list: ${e}` })
      }
      this.loadingAds = false
    },
    async getFeaturePositions () {
      if (this.destinationAdId && this.destinationPage) {
        this.loadingFeaturePositions = true
        try {
          const page = `PAGE_POSITION_${this.destinationPage}`
          const { data } = await Features.getFeaturePositions(this.destinationAdId, { page: page })
          this.featurePositions = data
          if (data.length > 0) {
            this.destinationFeaturePositionId = this.featurePositions[0].id
          }
        } catch (e) {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to retrieve feature positions: ${e}` })
        }
        this.loadingFeaturePositions = false
      }
    },
    async copy (operation) {
      if (this.destinationAdId && this.destinationPage && this.destinationFeaturePositionId) {
        const destinationAd =  this.ads.find(ad => this.destinationAdId === ad.id)

        const payload = {
          move: operation === 'move',
          source: destinationAd.item_source,
          feature_id: this.selectedFeature.id,
          dest_ad_id: this.destinationAdId,
          dest_page_id: this.pages.find(page => page.constant === this.destinationPage).id,
          dest_position_id: this.destinationFeaturePositionId,
          dest_pig_ids: destinationAd.dest_pig_ids ? destinationAd.dest_pig_ids : []
        }
        try {
          this.copyInProgress = true
          await Features.copyAdFeature(payload)
          const successText = operation === 'move' ? 'Feature Moved' : `Feature Copied`
          this.$store.dispatch('setSnackbar', { status: 'success', text: successText })
          this.$emit('closeFeatureView')
          this.$emit('update:value', false)
        } catch (e) {
          this.copyInProgress = false
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to ${operation} feature: ${e}` })
        }
      }
    },
    confirmMove () {
      this.closeConfirmDialog()
      this.copy('move')
    },
    openConfirmDialog () {
      this.confirmDialog = true
    },
    closeConfirmDialog () {
      this.confirmDialog = false
    }
  }
}
</script>
